<script>
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'My Investment': 'Meu Investimento',
      'My Profit': 'Meu Rendimento',
      'My Team': 'Minha Equipe',
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bônus',
      'Current Balance': 'Saldo Atual',
      'Graduation': 'Graduação',
      'No Graduation': 'Sem Graduação',
      'Points': 'Pontos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    },
    es: {
      'My Investment': 'Mi Inversion',
      'My Profit': 'Mi Rendimiento',
      'My Team': 'Mi Equipo',
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bonus',
      'Current Balance': 'Saldo Actual',
      'Graduation': 'Graduación',
      'No Graduation': 'No Graduación',
      'Points': 'Puntos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    }
  },
  props: {
    type: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      renew: {
        loading: false,
      }
    }
  },
  methods: {
    renewPlan() {
      this.renew.loading = true;

      api
        .post("tv/subscribers/renew")
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.order &&
              response.data.order.id) {
              this.$router.push("/store/order/" + response.data.order.id);
            } else {
              this.renew.loading = false;
              this.$noty.error(response.data.message);
            }
          } else {
            this.renew.loading = false;
            this.$noty.error(response.data.message);
          }

          this.renew.loading = false;
        });
    },
  },
};
</script>

<template>
  <div class="card bg-light text-dark">
    <div class="card-body py-3">
      <div v-if="type == 'center'" class="text-center">
        <h5 v-if="value == 'active'" class="mb-0 notranslate text-white font-weight-bold"><span class="bg-success rounded text-dark px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate text-white font-weight-bold"><span class="bg-danger rounded text-dark px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'pending'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'approved'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">{{ t(value) }}</h5>
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
      </div>
      <div v-else>
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
        <h5 v-if="value == 'active'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate text-dark font-weight-bold d-flex">
          <div class="flex-fill">
            <span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span>
          </div>
          <div style="display:block; margin-top: -15px;">
            <button class="btn btn-success btn-sm font-size-15" v-on:click="renewPlan()">
              <span class="notranslate">RENOVAR</span>
              <b-spinner v-if="renew.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </button>
          </div>
        </h5>
        <h5 v-else-if="value == 'pending'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'approved'" class="mb-0 notranslate text-dark font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="title == 'Usuário'" class="mb-0 notranslate text-dark font-weight-bold">
          <span>{{ value }}</span>
          <button class="btn btn-link p-0 m-0 align-bottom" v-clipboard:copy="value" v-on:click="$noty.success('O usuário foi copiado.')">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </h5>
        <h5 v-else-if="title == 'Senha'" class="mb-0 notranslate text-dark font-weight-bold">
          <span>{{ value }}</span>
          <button class="btn btn-link p-0 m-0 align-bottom" v-clipboard:copy="value" v-on:click="$noty.success('A senha foi copiada.')">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">{{ t(value) }}</h5>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  line-height: 20px;
}
.card-body button {
  position: absolute;
  right: 15px;
  bottom: 10px;
}
.bg {
  background-color: #e0e0e0;
}
</style>