import Vue from "vue";
import Router from "vue-router";

import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      component: () => import("./views/home"),
      meta: { authRequired: true },
    },
    {
      path: "/login",
      component: () => import("./views/login"),
      meta: { authRequired: false },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: { authRequired: false },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/register",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: { authRequired: false },
    },
    {
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: { authRequired: false },
    },
    {
      path: "/account",
      component: () => import("./views/account/account"),
      meta: { authRequired: true },
    },
    {
      path: "/account/documents",
      component: () => import("./views/account/documents"),
      meta: { authRequired: true },
    },
    {
      path: "/account/indication",
      component: () => import("./views/account/indication"),
      meta: { authRequired: true },
    },
    {
      path: "/account/address",
      component: () => import("./views/account/address"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract-network"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/transfer",
      component: () => import("./views/wallet/transfer"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/pay",
      component: () => import("./views/wallet/pay"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/pay/:id",
      component: () => import("./views/wallet/pay"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: { authRequired: true },
    },
    {
      path: "/network/clients",
      component: () => import("./views/network/clients"),
      meta: { authRequired: true },
    },
    {
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: { authRequired: true },
    },
    {
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: { authRequired: true },
    },
    {
      path: "/network/matriz",
      component: () => import("./views/network/matriz"),
      meta: { authRequired: true },
    },
    {
      path: "/network/matriz/:id",
      component: () => import("./views/network/matriz"),
      meta: { authRequired: true },
    },
    {
      path: "/network/unilevel",
      component: () => import("./views/network/unilevel"),
      meta: { authRequired: true },
    },
    {
      path: "/network/unilevel/:id",
      component: () => import("./views/network/unilevel"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/indicacao",
      component: () => import("./views/reports/indicacao"),
      meta: { authRequired: true },
    },
    {
      path: "/reports/graduation",
      component: () => import("./views/reports/graduation"),
      meta: { authRequired: true },
    },
    {
      path: "/store",
      redirect: "/store/featured",
    },
    {
      path: "/store/featured",
      component: () => import("./views/store/featured"),
      meta: { authRequired: true },
    },
    {
      path: "/store/category/:id",
      component: () => import("./views/store/category"),
      meta: { authRequired: true },
    },
    {
      path: "/store/search",
      component: () => import("./views/store/search"),
      meta: { authRequired: true },
    },
    {
      path: "/store/cart",
      component: () => import("./views/store/cart"),
      meta: { authRequired: true },
    },
    {
      path: "/store/orders",
      component: () => import("./views/store/orders"),
      meta: { authRequired: true },
    },
    {
      path: "/store/order/:id",
      component: () => import("./views/store/order"),
      meta: { authRequired: true },
    },
    {
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: { authRequired: true },
    },
    {
      path: "/faq",
      component: () => import("./views/faq"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: { authRequired: true },
    },
    {
      path: "/courses",
      component: () => import("./views/courses/list"),
      meta: { authRequired: true },
    },
    {
      path: "/courses/:course",
      component: () => import("./views/courses/view"),
      meta: { authRequired: true },
    },
    {
      path: "/courses/:course/:module/:lesson",
      component: () => import("./views/courses/view"),
      meta: { authRequired: true },
    },
    {
      path: "/tv/plans",
      component: () => import("./views/tv/plans"),
      meta: { authRequired: true },
    },
    {
      path: "/tv/plans/:id",
      component: () => import("./views/tv/plan"),
      meta: { authRequired: true },
    },
    {
      path: "/tv/subscriber",
      component: () => import("./views/tv/subscriber"),
      meta: { authRequired: true },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: { authRequired: false },
    },
    {
      path: "/:sponsor",
      redirect: "/register/:sponsor",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  document.title = process.env.VUE_APP_TITLE;
  next();

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          router.app.$store.state.account.user = response.data.user;

          next();
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  } else {
    next();
  }
});

export default router;
